// Here you can add other styles
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');
/*****Ajustes Globales****/

html * {
  overflow: hidden;
  font-family: 'Roboto';

  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
}

/*********Contenedor con menu sidebar********/

.cs-dashboard {
  padding: 0px 0px 0px 0px;
  max-height: calc(80vh - 0rem);
  overflow-y: auto;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 3rem;
}

.cs-user {
  color: #22d989;
}

.cs-color-text {
  color: #22d989;
}

.cs-color-text a:link {
  color: #22d989;
}

.cs-user-session {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-right: 20px;
}

div.cs-user-session-body {
  -ms-flex: 1;
  flex: 1;
}

.cs-dashboard-footer {
  position: relative;
  height: 3rem;
  background-color: #22d989;
  /*to make it visible*/
}

.cs-dashboard-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /*    margin-right: 20px;
    margin-left: 30px; */
  margin-top: 10px;
  /*     margin-bottom: 10px; */
  padding-left: 20px;
  padding-right: 20px;
}

.cs-dashboard::-webkit-scrollbar {
  width: 20px;
}

.cs-dashboard::-webkit-scrollbar-track {
  background-color: transparent;
}

.cs-dashboard::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.cs-dashboard::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.btn-addscript,
.btn-addscript:hover,
.btn-addscript:active,
.btn-addscript.active,
.btn-addscript:visited {
  background-color: #22d989 !important;
  color: white;
  font-size: small;
  border-radius: 10px;
}

form.nosubmit {
  border: none;
  padding: 0;
}

input.nosubmit {
  border: 1px solid #b3b3b3;
  display: block;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
}

/********Contenedor con scroll Y***********/

.cs-panel-scripts {
  //padding: 15px 20px 0px 20px;
  max-height: calc(80vh - 1rem);
  overflow-y: auto;
  width: 100vw;
  padding-left: 60px;
  padding-right: 60px;
  /* margin-top: 20px; */
  margin-top: 0px;
}

/*****Scroll personalizado ,se crea con la clase "cs-panel-scripts" ****************/

.cs-panel-scripts::-webkit-scrollbar {
  width: 20px;
}

.cs-panel-scripts::-webkit-scrollbar-track {
  background-color: transparent;
}

.cs-panel-scripts::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.cs-panel-scripts::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

/*********Contenedor Renglon ****************/

.cs-panel-scripts-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 20px;
  margin-left: 30px;
  margin-top: 10px;
  /*     margin-bottom: 10px; */
  /* padding-left: 3px;
    padding-right: 3px; */
}

/**************PANEL DE TARJETAS CUSTOM SCRIPTS*****************/

/****Ajuste de imagen Logo en encabezado*********/

.cs-logo {
  /*  margin-left: 30px; */
  height: 4rem;
}

/********Subtitulo para panel de tarjetas********/

.cs-panel-subtitle {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  /* padding-top: 20px;
    padding-left: 32px; */
}

.cs-panel-subtitle-script {
  font-size: large;
  font-weight: 600;
}

.cs-text-small {
  font-size: small;
}

/******Ajuste de tamaño al encontrar el tag "form"***/

.cs-panel-scripts-row > form {
  position: relative;
  width: 100%;
}

/*********Dropdown ***********/

.cs-select {
  font-family: Roboto;
  overflow-y: scroll;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 20px;
}

/*****Scroll personalizado, se crea con la clase "cs-select" ****************/

.cs-select::-webkit-scrollbar {
  width: 20px;
  margin-bottom: 50px;
}

.cs-select::-webkit-scrollbar-track {
  background-color: transparent;
}

.cs-select::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.cs-select::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

select.cs-select[multiple],
select.cs-select[size] {
  height: auto;
}

/********Tarjeta que se muestra en el menu, aplica cambios a "span" y "button" para agregar titulo y descripcion************/

.cs-panel-card {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0, 0, 0, 0 !important;
  background-clip: border-box;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 1px 0px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 0px 0 rgba(0, 0, 0, 0.24) !important;
  // box-shadow:    0px 0px 0px 0px rgba(0, 0, 0, 0.12), 0px 1px 0px 0 rgba(0, 0, 0, 0.24) !important;
  //box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.12),     0px 1px 2px 0 rgba(0, 0, 0, 0.24) !important;
  // margin-top: 10px;
  // margin-bottom: 5px;
  // margin-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  width: 100%;
  height: 100%;
  margin-left: 0px;
  margin-right: 0px;
}
.cs-panel-card-size {
  // width: 270px!important;
  // min-width: 270px!important;
  height: 130px !important;
  min-height: 130px !important;
}
.cs-panel-title-card > a {
  color: #22d989;
  background-color: transparent;
  background-image: none;
  padding-top: 0px;
  margin-top: 0px;
  margin-right: 0px;
  padding-right: 0px;
}

.cs-panel-title-card > span {
  font-size: medium;
  color: #4a4a4a;
}

.cs-panel-title-card {
  font-size: medium;
  color: #4a4a4a;
  font-weight: 400 !important;
}

.cs-panel-description-card {
  color: #808080 !important;
  font-size: small !important;
  margin-bottom: 0 !important;
  font-size: medium;
  line-height: 1.4;
}

/*********PANEL DE FORMULARIO DE DATOS*************/

/*********Titulo*************/

.cs-panel-title-header {
  // color: black !important;
  font-size: larger;
  font-weight: 470;
  line-height: 24px;
  /* identical to box height, or 133% */
  letter-spacing: 0.15px;
  color: #4a4a4a;
  padding-top: 10px;

  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
}

/*********Boton Cerrar*********/

.cs-panel-close {
  color: #757575 !important;

  font-size: x-large;
  margin-top: 17px;
  margin-right: 40px;
}

/*********Encabezado añadir a nav**********/

.cs-panel-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 3px solid #22d989;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  /* margin-bottom: 15px; */
}

/**********Pie de pagina***********/

.cs-panel-footer {
  background-color: #22d989;
  height: 25px;
  text-align: right; /* Alinea el texto a la derecha */
  display: flex;
  align-items: center; /* Centra el contenido verticalmente */
  justify-content: flex-end; /* Alinea el contenido a la derecha */
  padding-right: 10px; /* Añade un poco de padding a la derecha */
  color: white;
  font-size: 10px;
}

/***********Data Script************/

/******Ajuste de controles de formulario*******/

.cs-text-form {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  line-height: 1.5;
  color: #000000;
  font-size: medium;
}

/*********Boton Siguiente*******/

.cs-btn-next,
.cs-btn-next:hover,
.cs-btn-next:active,
.cs-btn-next:visited {
  background-color: #22d989 !important;
  color: white !important;
  margin: 5px 5px 5px 5px !important;
}

.cs-btn,
.cs-btn:hover,
.cs-btn:active,
.cs-btn:visited {
  background-color: #22d989 !important;
  color: white;
}

.cs-btn-input,
.cs-btn-input:hover,
.cs-btn-input:active,
.cs-btn-input:visited {
  background-color: #22d989 !important;
  color: white;
}

/**********Alineacion de pie de pagina IZQUIERDA********/

.cs-align-button-left {
  padding-left: 30px;
}

/**********Alineacion de pie de pagina DERECHA********/

.cs-align-button-right {
  padding-right: 40px;
}

/********Boton Regresar***********/

.cs-btn-back,
.cs-btn-back:hover,
.cs-btn-back:active,
.cs-btn-back:visited {
  color: #22d989 !important;
  background-color: transparent !important;
  border-color: #22d989 !important;
  margin: 5px 5px 5px 5px !important;
  // width: 120px !important;
}

/****Navbar****/

.cs-navbar {
  position: relative !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  padding-top: 22px !important;
  padding-right: 1rem !important;
}

/******Sidebar****/

#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 120px;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 0px;
  width: 0;
  height: 100%;
  width: 150px;
  overflow-y: auto;
  background: #343a40;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 120px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: 0px;
}

/* Sidebar Styles */

.sidebar-nav {
  position: absolute;
  top: 0;
  /* width: 250px; */
  width: 130px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  text-indent: 20px;
  line-height: 40px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #999999;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
}

.sidebar-nav > .sidebar-brand a {
  color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none;
}

@media (min-width: 768px) {
  #wrapper {
    padding-left: 0;
  }

  #wrapper.toggled {
    padding-right: 0px;
  }

  #sidebar-wrapper {
    width: 0;
  }

  #wrapper.toggled #sidebar-wrapper {
    width: 130px;
  }

  #page-content-wrapper {
    padding: 20px;
    position: relative;
  }

  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}

/************************/

.footer-style {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  height: 2rem;
  background-color: #22d989 !important;
}

.bg-green {
  background-color: #22d989 !important;
}

/********Cambio de color en menu **************/

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #22d989 !important;
  color: #ffffff !important;
}

/************Input Inicio Sesion*************/

.float-label-control {
  position: relative;
  margin-bottom: 0.5em;
}

.float-label-control ::-webkit-input-placeholder {
  color: transparent;
}

.float-label-control :-moz-placeholder {
  color: transparent;
}

.float-label-control ::-moz-placeholder {
  color: transparent;
}

.float-label-control :-ms-input-placeholder {
  color: transparent;
}

.float-label-control input:-webkit-autofill,
.float-label-control textarea:-webkit-autofill {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -moz-box-shadow: 0 0 0 1000px white inset !important;
  box-shadow: 0 0 0 1000px white inset !important;
}

.float-label-control input,
.float-label-control textarea,
.float-label-control label {
  font-size: 1.3em;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.float-label-control input:focus,
.float-label-control textarea:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
  border-bottom-width: 2px;
  padding-bottom: 0;
}

.float-label-control textarea:focus {
  padding-bottom: 4px;
}

.float-label-control input,
.float-label-control textarea {
  display: block;
  width: 100%;
  padding: 0.1em 0em 1px 0em;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #aaa;
  outline: none;
  margin: 0px;
  background: none;
}

.float-label-control textarea {
  padding: 0.1em 0em 5px 0em;
}

.float-label-control label {
  position: absolute;
  font-weight: normal;
  /*  top: -1.0em; */
  left: 0.08em;
  color: #aaaaaa;
  z-index: -1;
  font-size: 0.85em;
  -moz-animation: float-labels 300ms none ease-out;
  -webkit-animation: float-labels 300ms none ease-out;
  -o-animation: float-labels 300ms none ease-out;
  -ms-animation: float-labels 300ms none ease-out;
  -khtml-animation: float-labels 300ms none ease-out;
  animation: float-labels 300ms none ease-out;
  /* There is a bug sometimes pausing the animation. This avoids that.*/
  animation-play-state: running !important;
  -webkit-animation-play-state: running !important;
}

.float-label-control input.empty + label,
.float-label-control textarea.empty + label {
  top: 0.1em;
  font-size: 1.5em;
  animation: none;
  -webkit-animation: none;
}

.float-label-control input:not(.empty) + label,
.float-label-control textarea:not(.empty) + label {
  z-index: 1;
}

.float-label-control input:not(.empty):focus + label,
.float-label-control textarea:not(.empty):focus + label {
  color: #aaaaaa;
}

.float-label-control.label-bottom label {
  -moz-animation: float-labels-bottom 300ms none ease-out;
  -webkit-animation: float-labels-bottom 300ms none ease-out;
  -o-animation: float-labels-bottom 300ms none ease-out;
  -ms-animation: float-labels-bottom 300ms none ease-out;
  -khtml-animation: float-labels-bottom 300ms none ease-out;
  animation: float-labels-bottom 300ms none ease-out;
}

.float-label-control.label-bottom input:not(.empty) + label,
.float-label-control.label-bottom textarea:not(.empty) + label {
  top: 3em;
}

@keyframes float-labels {
  0% {
    opacity: 1;
    color: #aaa;
    top: 0.1em;
    font-size: 1.5em;
  }

  20% {
    font-size: 1.5em;
    opacity: 0;
  }

  30% {
    top: 0.1em;
  }

  50% {
    opacity: 0;
    font-size: 0.85em;
  }

  100% {
    top: -1em;
    opacity: 1;
  }
}

@-webkit-keyframes float-labels {
  0% {
    opacity: 1;
    color: #aaa;
    top: 0.1em;
    font-size: 1.5em;
  }

  20% {
    font-size: 1.5em;
    opacity: 0;
  }

  30% {
    top: 0.1em;
  }

  50% {
    opacity: 0;
    font-size: 0.85em;
  }

  100% {
    top: -1em;
    opacity: 1;
  }
}

@keyframes float-labels-bottom {
  0% {
    opacity: 1;
    color: #aaa;
    top: 0.1em;
    font-size: 1.5em;
  }

  20% {
    font-size: 1.5em;
    opacity: 0;
  }

  30% {
    top: 0.1em;
  }

  50% {
    opacity: 0;
    font-size: 0.85em;
  }

  100% {
    top: 3em;
    opacity: 1;
  }
}

@-webkit-keyframes float-labels-bottom {
  0% {
    opacity: 1;
    color: #aaa;
    top: 0.1em;
    font-size: 1.5em;
  }

  20% {
    font-size: 1.5em;
    opacity: 0;
  }

  30% {
    top: 0.1em;
  }

  50% {
    opacity: 0;
    font-size: 0.85em;
  }

  100% {
    top: 3em;
    opacity: 1;
  }
}

/*******TABS***************/

.cs-nav {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  background-clip: border-box;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.12), 1px 1px 2px 0 rgba(0, 0, 0, 0.24);
  margin-top: 10px;
  margin-bottom: 5px;
  margin-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.cs-tab {
  padding: 10%;
  margin-top: -8%;
}

.cs-tab #tabs {
  background: #007b5e;
  color: #eee;
}

.cs-tab #tabs h6.section-title {
  color: #eee;
}

.cs-tab #tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #22d989;
  background-color: transparent;
  border-color: transparent transparent #f3f3f3;
  /*     border-color: #999999;
 */
  border-bottom: 3px solid !important;
  font-size: small;
  /* font-weight: bold; */
}

.nav-tabs .nav-link {
  color: #c2cbce;
  background-color: transparent;
  border-color: transparent transparent #f3f3f3;
  /*     border-color: #999999;
 */
  font-size: small;
}

.cs-tab .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #22d989;
  font-size: small;
  font-weight: 600;
}

.cs-tab .nav-link:hover {
  border: none;
}

.cs-tab thead {
  background: #f3f3f3;
  color: #333;
}

.cs-tab a {
  text-decoration: none;
  color: #333;
  font-weight: 600;
}

/******************/

.cs-button-default,
.cs-button-default:hover,
.cs-button-default:active,
.cs-button-default.active,
.cs-button-default:visited {
  color: #22d989;
  background-color: transparent;
  border-color: #22d989;
  border-radius: 10px;
  font-size: small;
}

.cs-button-default > i {
  margin-top: 3px;
}

/********checkbox************/
/* a:before {
    font-family: "Font Awesome 5 Free";
    content: "\f00c";


    font-weight: 600;
} */

.abc-checkbox {
  cursor: default;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.abc-checkbox label {
  cursor: pointer;
  display: inline;
  vertical-align: top;
  position: relative;
  padding-left: 5px;
}

.abc-checkbox label::before {
  cursor: pointer;
  content: '';
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 2px;
  left: 0;
  margin-left: -1.25rem;
  border: 2px solid #ced4da;
  border-radius: 3px;
  background-color: #fff;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .abc-checkbox label::before {
    transition: none;
  }
}

.abc-checkbox label::after {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 3px;
  left: 2px;
  margin-left: -1.25rem;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #495057;
}

.abc-checkbox input[type='checkbox'],
.abc-checkbox input[type='radio'] {
  position: static;
  margin-left: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
}

.abc-checkbox input[type='checkbox']:focus + label::before,
.abc-checkbox input[type='radio']:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.abc-checkbox input[type='checkbox']:checked + label::after,
.abc-checkbox input[type='radio']:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: '\f00c';
  font-weight: 600;
}

.abc-checkbox input[type='checkbox']:indeterminate + label::after,
.abc-checkbox input[type='radio']:indeterminate + label::after {
  /* display: block; */
  content: '';
  width: 10px;
  height: 3px;
  background-color: #555555;
  border-radius: 2px;
  margin-left: -16.5px;
  margin-top: 7px;
}

.abc-checkbox input[type='checkbox']:disabled + label,
.abc-checkbox input[type='radio']:disabled + label {
  opacity: 0.65;
}

.abc-checkbox input[type='checkbox']:disabled + label::before,
.abc-checkbox input[type='radio']:disabled + label::before {
  background-color: #e9ecef;
  cursor: not-allowed;
}

.abc-checkbox input[type='checkbox']:disabled + label::after,
.abc-checkbox input[type='radio']:disabled + label::after {
  cursor: not-allowed;
}

.abc-checkbox.abc-checkbox-circle label::before {
  border-radius: 50%;
}

.abc-checkbox.checkbox-inline {
  margin-top: 0;
}

.abc-checkbox-primary input[type='checkbox']:checked + label::before,
.abc-checkbox-primary input[type='radio']:checked + label::before {
  background-color: #007bff;
  border-color: #007bff;
}

.abc-checkbox-primary input[type='checkbox']:checked + label::after,
.abc-checkbox-primary input[type='radio']:checked + label::after {
  color: #fff;
}

.abc-checkbox-danger input[type='checkbox']:checked + label::before,
.abc-checkbox-danger input[type='radio']:checked + label::before {
  background-color: #dc3545;
  border-color: #dc3545;
}

.abc-checkbox-danger input[type='checkbox']:checked + label::after,
.abc-checkbox-danger input[type='radio']:checked + label::after {
  color: #fff;
}

.abc-checkbox-info input[type='checkbox']:checked + label::before,
.abc-checkbox-info input[type='radio']:checked + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.abc-checkbox-info input[type='checkbox']:checked + label::after,
.abc-checkbox-info input[type='radio']:checked + label::after {
  color: #fff;
}

.abc-checkbox-warning input[type='checkbox']:checked + label::before,
.abc-checkbox-warning input[type='radio']:checked + label::before {
  background-color: #ffc107;
  border-color: #ffc107;
}

.abc-checkbox-warning input[type='checkbox']:checked + label::after,
.abc-checkbox-warning input[type='radio']:checked + label::after {
  color: #fff;
}
@keyframes checkbox-check {
  0% {
    width: 0;
    height: 0;
    border-color: #212121;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  33% {
    width: 0.2em;
    height: 0;
    transform: translate3d(0, 0, 0) rotate(45deg);
  }
  100% {
    width: 0.2em;
    height: 0.5em;
    border-color: #212121;
    transform: translate3d(0, -0.5em, 0) rotate(45deg);
  }
}
.abc-checkbox-success input[type='checkbox']:checked + label::before,
.abc-checkbox-success input[type='radio']:checked + label::before {
  background-color: #22d989;
  border-color: #22d989;
  opacity: 0;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 0.2s;
  animation-delay: 0.2s;
}

.abc-checkbox-success input[type='checkbox']:checked + label::after,
.abc-checkbox-success input[type='radio']:checked + label::after {
  color: #fff;
}

.abc-checkbox-primary input[type='checkbox']:indeterminate + label::before,
.abc-checkbox-primary input[type='radio']:indeterminate + label::before {
  background-color: #007bff;
  border-color: #007bff;
}

.abc-checkbox-primary input[type='checkbox']:indeterminate + label::after,
.abc-checkbox-primary input[type='radio']:indeterminate + label::after {
  background-color: #fff;
}

.abc-checkbox-danger input[type='checkbox']:indeterminate + label::before,
.abc-checkbox-danger input[type='radio']:indeterminate + label::before {
  background-color: #dc3545;
  border-color: #dc3545;
}

.abc-checkbox-danger input[type='checkbox']:indeterminate + label::after,
.abc-checkbox-danger input[type='radio']:indeterminate + label::after {
  background-color: #fff;
}

.abc-checkbox-info input[type='checkbox']:indeterminate + label::before,
.abc-checkbox-info input[type='radio']:indeterminate + label::before {
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.abc-checkbox-info input[type='checkbox']:indeterminate + label::after,
.abc-checkbox-info input[type='radio']:indeterminate + label::after {
  background-color: #fff;
}

.abc-checkbox-warning input[type='checkbox']:indeterminate + label::before,
.abc-checkbox-warning input[type='radio']:indeterminate + label::before {
  background-color: #ffc107;
  border-color: #ffc107;
}

.abc-checkbox-warning input[type='checkbox']:indeterminate + label::after,
.abc-checkbox-warning input[type='radio']:indeterminate + label::after {
  background-color: #fff;
}

.abc-checkbox-success input[type='checkbox']:indeterminate + label::before,
.abc-checkbox-success input[type='radio']:indeterminate + label::before {
  background-color: #28a745;
  border-color: #28a745;
}

.abc-checkbox-success input[type='checkbox']:indeterminate + label::after,
.abc-checkbox-success input[type='radio']:indeterminate + label::after {
  background-color: #fff;
}

.abc-radio {
  cursor: default;
  padding-left: 4px;
}

.abc-radio label {
  cursor: pointer;
  display: inline;
  vertical-align: top;
  position: relative;
  padding-left: 5px;
}

.abc-radio label::before {
  cursor: pointer;
  content: '';
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  top: 2px;
  left: 0;
  margin-left: -20px;
  border: 5px solid #ced4da;
  border-radius: 50%;
  background-color: #fff;
  transition: border 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .abc-radio label::before {
    transition: none;
  }
}

.abc-radio label::after {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  content: ' ';
  width: 11px;
  height: 11px;
  left: 3px;
  top: 5px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #495057;
  transform: scale(0, 0);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.abc-radio input[type='radio'] {
  position: static;
  margin-left: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
}

.abc-radio input[type='radio']:focus + label::before {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.abc-radio input[type='radio']:checked + label::after {
  transform: scale(1, 1);
}

.abc-radio input[type='radio']:disabled + label {
  opacity: 0.65;
}

.abc-radio input[type='radio']:disabled + label::before {
  cursor: not-allowed;
}

.abc-radio input[type='radio']:disabled + label::after {
  cursor: not-allowed;
}

.abc-radio.radio-inline {
  margin-top: 0;
}

.abc-radio-primary input[type='radio'] + label::after {
  background-color: #007bff;
}

.abc-radio-primary input[type='radio']:checked + label::before {
  border-color: #007bff;
}

.abc-radio-primary input[type='radio']:checked + label::after {
  background-color: #007bff;
}

.abc-radio-danger input[type='radio'] + label::after {
  background-color: #dc3545;
}

.abc-radio-danger input[type='radio']:checked + label::before {
  border-color: #dc3545;
}

.abc-radio-danger input[type='radio']:checked + label::after {
  background-color: #dc3545;
}

.abc-radio-info input[type='radio'] + label::after {
  background-color: #17a2b8;
}

.abc-radio-info input[type='radio']:checked + label::before {
  border-color: #17a2b8;
}

.abc-radio-info input[type='radio']:checked + label::after {
  background-color: #17a2b8;
}

.abc-radio-warning input[type='radio'] + label::after {
  background-color: #ffc107;
}

.abc-radio-warning input[type='radio']:checked + label::before {
  border-color: #ffc107;
}

.abc-radio-warning input[type='radio']:checked + label::after {
  background-color: #ffc107;
}

.abc-radio-success input[type='radio'] + label::after {
  background-color: #28a745;
}

.abc-radio-success input[type='radio']:checked + label::before {
  border-color: #28a745;
}

.abc-radio-success input[type='radio']:checked + label::after {
  background-color: #28a745;
}

label .was-validated .form-check-input:invalid .abc-checkbox:before,
label .was-validated .form-check-input:invalid .abc-radio:before,
label .form-check-input.is-invalid .abc-checkbox:before,
label .form-check-input.is-invalid .abc-radio:before {
  border-color: #dc3545;
}

/*# sourceMappingURL=build.css.map */

/******hipervinculo desplogable********/
.hipervinculo {
  color: #22d989 !important;
  background-color: transparent;
  text-decoration: none !important;
  cursor: pointer !important;
}
.hipervinculo :link {
  color: #22d989 !important;
  background-color: transparent;
  text-decoration: none !important;
  cursor: pointer !important;
}

.hipervinculo :hover {
  color: #22d989;
  background-color: transparent;
  text-decoration: none !important;
  transform: scale(1.2);
  cursor: pointer !important;
}

.hipervinculo :active {
  color: #22d989;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer !important;
}

/****Icono*****/
.icono :hover {
  color: #22d989;
  background-color: transparent;
  text-decoration: none;
  transform: scale(1.3);
  height: 20px;
  bottom: 0%;
  z-index: -99;
}

/**************Alerts**********************/
.cs-alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.cs-alert-success {
  color: #fff;
  background-color: #22d989;
  border-color: #c3e6cb;
}

.cs-alert-success hr {
  border-top-color: #28a745;
}

.cs-alert-success .alert-link {
  color: #22d989;
}

.cs-alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #d0021b;
}

.cs-alert-danger hr {
  border-top-color: #f1b0b7;
}

.cs-alert-danger .alert-link {
  color: #491217;
}

.cs-alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.cs-alert-light hr {
  border-top-color: #ececf6;
}

.cs-alert-light .alert-link {
  color: #686868;
}

/***********paginacion**************/
.cs-paginacion {
  bottom: 80px;
}

/*********Alerta*********/

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fade-in {
  opacity: 0;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: 0.3s;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fade-out {
  opacity: 1;
  animation: fadeOut ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 1s;
  animation-delay: 0.3s;
}
/*****************MODAL****************/
.cs-modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;

  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.cs-modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1rem;
  margin-top: 15px;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.cs-modal-close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  margin-left: -20px;
}
.textoAlertaTitulo {
  position: static;
  left: 5.21%;
  right: 5.21%;
  top: 10%;
  bottom: 5%;
  /* Title Medium 24 */
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* or 133% */
  /* text-align: center; */
  letter-spacing: 0.252px;
  /* text_primary */
  color: #4a4a4a;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  /* margin: 13px 8px; */
  /* padding-left: 10%; */
  padding-top: 5%;
}
.textoAlertaDescripcion {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  /* or 150% */
  text-align: center;
  letter-spacing: 0.168px;
  color: #4a4a4a;
}
/********boton invertido ********/
.cs-modal-btn-blanco,
.cs-modal-btn-blanco:hover,
.cs-modal-btn-blanco:active,
.cs-modal-btn-blanco:visited {
  color: #22d989;
  background-color: transparent;
  border-color: #22d989;
  margin: 5px 5px 5px 5px;
  width: 120px;
}

.cs-modal-btn,
.cs-modal-btn:hover,
.cs-modal-btn:active,
.cs-modal-btn:visited {
  background-color: #22d989 !important;
  color: white;
  margin: 5px 5px 5px 5px;
  width: 120px;
}

.cs-modal-btn-peligro,
.cs-modal-btn-peligro:hover,
.cs-modal-btn-peligro:active,
.cs-modal-btn-peligro:visited {
  color: #fff;
  background-color: #ff385c;
  border-color: #ff385c;
  margin: 5px 5px 5px 5px;
  width: 120px;
}

/*******Table***************/

.cs-table::-webkit-scrollbar {
  width: 20px;
}

.cs-table::-webkit-scrollbar-track {
  background-color: transparent;
}

.cs-table::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.cs-table::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
/****************************************/
.centered-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/****************************************************/
.custom-badge-active {
  font-size: 1rem !important;
  color: white !important;
  background-color: #22d989 !important;
}
.custom-badge-inactive {
  font-size: 1rem !important;
  color: white !important;
  background-color: #ff385c !important;
}

.pagination-controls {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px; /* Añadir un poco de margen superior si es necesario */
}

.pagination-controls button {
  margin: 0 5px; /* Espaciado entre los botones */
}

.pagination-controls button.active {
  font-weight: bold;
  background-color: #007bff; /* Color de fondo para el botón activo */
  color: white;
}

/**********************************/
.cs-btn-standar,
.cs-btn-standar:hover,
.cs-btn-standar:active,
.cs-btn-standar:visited {
  background-color: #22d989 !important;
  color: white !important;
  margin: 5px !important;
  padding: 10px 20px; /* Espacio alrededor del texto */
  border: none; /* Elimina bordes */
  border-radius: 5px; /* Añade un poco de borde redondeado */
  font-size: 16px; /* Ajusta el tamaño de la fuente */
  transition: background-color 0.3s ease; /* Añade una transición suave */
  white-space: nowrap; /* Evita que el texto se rompa en varias líneas */
  display: inline-block; /* Asegura que el botón se ajuste al contenido */
}

.c-breadcrumb-item a {
  text-decoration: none !important; /* Quitar subrayado con !important */
  color: #878787 !important; /* Cambiar color del enlace */
}
.custom-accordion-header {
  background-color: #f0f8ff !important; /* Cambia este color al que desees */
  color: #000 !important; /* Cambia el color del texto si es necesario */
  padding: 10px !important; /* Opcional: ajusta el espaciado */
  border-radius: 5px !important; /* Opcional: añade bordes redondeados */
}


.badge-pill {
  display: inline-block;
  padding: 0.25em 0.6em;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: white;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 10px;
}

.badge-primary {
  background-color: #007bff;
}

.badge-secondary {
  background-color: #6c757d;
}

.badge-success {
  background-color: #28a745;
}

//**************checkbox
/* Personalización del checkbox */
input.form-check-input.custom-checkbox {
  width: 24px !important;
  height: 24px !important;
  border-radius: 6px !important;
  border: 2px solid #007bff !important; /* Azul */
  background-color: white !important;
  cursor: pointer;
  transition: all 0.2s ease-in-out !important;
}

/* Color cuando está seleccionado */
input.form-check-input.custom-checkbox:checked {
  background-color: #007bff !important;
  border-color: #0056b3 !important;
}

/* Checkbox deshabilitado */
input.form-check-input.custom-checkbox:disabled {
  background-color: #d6d6d6 !important;
  border-color: #b0b0b0 !important;
  cursor: not-allowed !important;
}

/* Efecto cuando recibe foco */
input.form-check-input.custom-checkbox:focus {
  box-shadow: 0 0 6px rgba(0, 123, 255, 0.5) !important;
}


.tab-title {
  font-size: 1.2rem !important; /* Tamaño de la fuente más grande */
  font-weight: bold; /* Opcional: Hace que el texto sea más grueso */
  padding: 10px 15px !important; /* Aumenta el padding para más espacio */
}





.no-scroll * {
  overflow: hidden !important;
}